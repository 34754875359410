import { useRuntimeConfig } from '#app';
import { useSeoMeta } from '#imports';
import { useAppStore } from '~/store/app';

interface useSeoMetaDataPayload {
  title: string;
  description: string;
  image?: string;
  relativeUrl: string;
}

export const useSeoMetaData = ({
  title,
  description,
  image,
  relativeUrl,
}: useSeoMetaDataPayload) => {
  const config = useRuntimeConfig();
  const store = useAppStore();

  const url = `${config.public.frontendDomain}${relativeUrl}`;

  const commonMetaData = {
    title,
    description,
    ogTitle: title,
    ogDescription: description,
    canonical: url,
    lang: store.locale,
    ogUrl: url,
  };

  const metaData = { ...commonMetaData };

  if (image) {
    metaData.ogImage = image;
  }

  return useSeoMeta({
    ...metaData,
  });
};
